import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { COLOR, FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'
import { DOCUMENT_URL, FORM_NAME } from 'constants/constants'
import Button from 'components/Button'
import Form from 'components/Form'
import { Box, Grid } from 'components/Layout'
import TextInput from 'components/TextInput'
import { Text } from 'components/Typography'
import { postForm } from 'utils/netlify-form'
import useMessage from 'utils/useMessage'
import Checkbox from 'components/Checkbox'
import SuccessMessage from './SuccessMessage'

const SOLD_OUT_SIGN_UP_BUTTON = 'Chcem byť informovaný'
const SIGN_UP_BUTTON = 'Zašli nám svoju prihlášku'
const EMAIL_REGEX = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/

const COURSE_TYPE = {
  JAVASCRIPT: 'JavaScript',
  PRODUCT_MANAGEMENT: 'ProductManager',
}

const { NAME, EMAIL, KNOWLEDGE, TYPE, AGREEMENT } = {
  NAME: 'name',
  EMAIL: 'email',
  KNOWLEDGE: 'knowledge',
  TYPE: 'type',
  AGREEMENT: 'agreement',
}

const INITIAL_INPUTS_STATE = {
  [NAME]: '',
  [EMAIL]: '',
  [KNOWLEDGE]: undefined,
  [AGREEMENT]: false,
}

const INITIAL_INPUTS_TOUCHED_STATE = {
  [NAME]: false,
  [EMAIL]: false,
}

/*
const JS_COURSE_OPTIONS = [
  {
    value: 'beginner',
    label: '1) Ovládam teóriu a základy JavaScriptu',
  },
  {
    value: 'experienced',
    label: '2) Využívam JavaScript profesionálne v praxi',
  },
  {
    value: 'other_language',
    label: '3) Žiadne, ale ovládam iný programovací jazyk',
  },
]
*/

const MarginWrapper = styled('div')`
  > * + * {
    margin-top: ${SPACE.L};
  }
`

const Description = styled('p')`
  font-size: ${FONT_SIZE.S};
  margin-top: ${SPACE.M};
`

const StyledBox = styled(Box)`
  margin-top: ${rem(48)};
`

const Ribbon = styled('span')`
  pointer-events: initial;
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: ${rem(30)};
  width: ${rem(200)};

  font-size: ${FONT_SIZE.XS};
  font-weight: ${FONT_WEIGHT.MEDIUM};

  background-color: ${COLOR.DUSK_ORANGE};
  border: solid ${COLOR.WHITE_50};
  border-width: 1px 0;
  color: ${COLOR.BLACK};

  transform: translate(${rem(45)}, ${rem(20)}) rotate(30deg);
`

const AdditionalInfoField = styled('div')`
  visibility: collapse;
  height: 0;
`

const Policies = ({ privacyOnly = false, courseType }) => (
  <Text as="p" fontSize={rem(14)} ml={SPACE.M}>
    Súhlasím s
    {privacyOnly ? (
      'o'
    ) : (
      <>
        {' '}
        <Text
          id={`application-form-privacy-policy-${courseType.toLowerCase()}`}
          as="a"
          target="_blank"
          href={DOCUMENT_URL.TRADING_CONDITIONS}
          color={COLOR.PRIMARY_RED}
        >
          obchodnými podmienkami
        </Text>
        {' a'}
      </>
    )}{' '}
    zásadami{' '}
    <Text
      id={`application-form-privacy-policy-${courseType.toLowerCase()}`}
      as="a"
      target="_blank"
      href={DOCUMENT_URL.PRIVACY_POLICY}
      color={COLOR.PRIMARY_RED}
    >
      ochrany osobných údajov
    </Text>
  </Text>
)

Policies.propTypes = {
  privacyOnly: PropTypes.bool,
  courseType: PropTypes.oneOf(Object.values(COURSE_TYPE)).isRequired,
}

const ApplicationForm = ({ courseType, coursePrice, isCourseSoldOut }) => {
  const { setErrorMessage } = useMessage()
  const [inputValues, setInputValues] = React.useState({
    ...INITIAL_INPUTS_STATE,
    [TYPE]: courseType,
  })
  const [areInputsTouched, setAreInputsTouched] = React.useState(
    INITIAL_INPUTS_TOUCHED_STATE
  )

  const [
    isFormSubmittedWithoutKnowledge,
    setIsFormSubmittedWithoutKnowledge,
  ] = React.useState(false)
  const [
    isSuccessMessageDisplayed,
    setIsSuccessMessageDisplayed,
  ] = React.useState(false)

  const handleInputChange = ({ target: { name, value, checked } }) => {
    setInputValues((prev) => ({
      ...prev,
      [name]: name === AGREEMENT ? checked : value,
    }))
  }

  const validateInput = (value, name, isTouched) => {
    if (!isTouched) {
      return true
    }
    if (name === EMAIL) {
      return EMAIL_REGEX.test(value)
    }
    return value.length > 0 && value !== ''
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const formData = new FormData(e.target)

      if (
        (courseType === COURSE_TYPE.JAVASCRIPT && !inputValues[KNOWLEDGE]) ||
        !inputValues[NAME] ||
        !inputValues[EMAIL] ||
        !inputValues[AGREEMENT] ||
        !validateInput(inputValues[EMAIL], EMAIL, areInputsTouched[EMAIL])
      ) {
        if (!inputValues[KNOWLEDGE]) {
          setIsFormSubmittedWithoutKnowledge(true)
        }
        return
      }
      if (isFormSubmittedWithoutKnowledge) {
        setIsFormSubmittedWithoutKnowledge(false)
      }
      const { knowledge, ...rest } = inputValues
      await postForm({
        body: {
          ...rest,
          [KNOWLEDGE]: knowledge?.value ?? '',
          netlify_honeypot: formData.get('additional-info'),
        },
        formName: FORM_NAME.APPLICATION,
      })
      if (window.fbq != null) {
        window.fbq('track', 'CompleteRegistration', {
          content_name: courseType,
          currency: 'EUR',
          status: 'TRUE',
          value: coursePrice,
        })
      }

      if (window.dataLayer != null) {
        window.dataLayer.push({ event: 'application' })
      }

      setIsSuccessMessageDisplayed(true)
      setTimeout(() => {
        setInputValues({
          ...INITIAL_INPUTS_STATE,
          [TYPE]: courseType,
        })
        setAreInputsTouched(INITIAL_INPUTS_TOUCHED_STATE)
        setIsSuccessMessageDisplayed(false)
      }, 5000)
    } catch (error) {
      setErrorMessage('Niekde nastala chyba, skúste to neskôr, prosím')
    }
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        title={
          isCourseSoldOut ? 'Vyplň nezáväznú prihlášku' : 'Vyplň prihlášku'
        }
        width={`min(100%, ${rem(400)})`}
        ribbonComp={isCourseSoldOut ? <Ribbon>Vypredané</Ribbon> : undefined}
        netlify-honeypot="additional-info"
        data-netlify="true"
        data-netlify-recaptcha="true"
      >
        <MarginWrapper>
          {/* Netlify honeypot for bot detection name additional-info is to trick bots */}
          <AdditionalInfoField>
            <input id="additional-info" name="additional-info" />
          </AdditionalInfoField>
          <Description>
            {isCourseSoldOut
              ? 'Prihlásenie do aktuálne prebiehajúceho kurzu už nieje možné. Nechaj nám tvoje údaje a pred ďalším kurzom ťa budeme kontaktovať.'
              : 'Po odoslaní ťa budeme kontaktovať ohľadom ďalších krokov prihlasovacieho procesu'}
          </Description>
          <TextInput
            label="MENO A PRIEZVISKO"
            placeholder="Tvoje meno a priezvisko"
            name={NAME}
            value={inputValues[NAME]}
            onChange={(e) => {
              if (!areInputsTouched[NAME]) {
                setAreInputsTouched((prevState) => ({
                  ...prevState,
                  [NAME]: true,
                }))
              }
              handleInputChange(e)
            }}
            isValid={validateInput(
              inputValues[NAME],
              NAME,
              areInputsTouched[NAME]
            )}
            required
          />
          <TextInput
            type="email"
            label="EMAIL"
            placeholder="Tvoj email"
            name={EMAIL}
            value={inputValues[EMAIL]}
            onChange={(e) => {
              if (!areInputsTouched[EMAIL]) {
                setAreInputsTouched((prevState) => ({
                  ...prevState,
                  [EMAIL]: true,
                }))
              }
              handleInputChange(e)
            }}
            isValid={validateInput(
              inputValues[EMAIL],
              EMAIL,
              areInputsTouched[EMAIL]
            )}
            required
          />
          {/* courseType === COURSE_TYPE.JAVASCRIPT && (
                <Select
                  label="Aké sú tvoje znalosti JavaScript-u?"
                  options={JS_COURSE_OPTIONS}
                  name={KNOWLEDGE}
                  value={inputValues[KNOWLEDGE]}
                  handleInputChange={(value) => {
                    setInputValues((prev) => ({ ...prev, [KNOWLEDGE]: value }))
                    if (isFormSubmittedWithoutKnowledge) {
                      setIsFormSubmittedWithoutKnowledge(false)
                    }
                  }}
                  isValid={!isFormSubmittedWithoutKnowledge}
                />
            ) */}
          <input type="hidden" name={TYPE} value={courseType} />
          {/* Netlify recaptcha for bot detection placeholder */}
          <div data-netlify-recaptcha="true" />

          <StyledBox>
            <Button
              id={`application-form-submit-${courseType.toLowerCase()}`}
              type="submit"
              isFullWidth
            >
              {isCourseSoldOut ? SOLD_OUT_SIGN_UP_BUTTON : SIGN_UP_BUTTON}
            </Button>
            <Grid
              alignItems="center"
              gridTemplateColumns="max-content 1fr"
              mt={SPACE.XM}
              mb={SPACE.S}
            >
              <Checkbox
                name={AGREEMENT}
                isChecked={inputValues[AGREEMENT]}
                handleChange={handleInputChange}
                required
              />
              <Policies courseType={courseType} />
            </Grid>
          </StyledBox>
        </MarginWrapper>
        <SuccessMessage
          $isVisible={isSuccessMessageDisplayed}
          title="Skvelé!"
          text={
            isCourseSoldOut
              ? 'Budeme ta kontaktovať pred začatím nového kurzu.'
              : 'Skontroluj svoj email, poslali sme ti ďalšie informácie.'
          }
        />
      </Form>
    </>
  )
}

ApplicationForm.propTypes = {
  coursePrice: PropTypes.number.isRequired,
  courseType: PropTypes.oneOf(Object.values(COURSE_TYPE)).isRequired,
  isCourseSoldOut: PropTypes.bool.isRequired,
}

export default ApplicationForm
